<template>
  <div class="pb grid_width">

    <div class="headerow">

      <h1>
        {{ $t('lights_view_title') }} 
      </h1>
  

        <GeneralSearchShort
          @search="changeKeyword"
          :val="keyword"
          :placeholder="$t('lights_view_search_placeholder')"
          />  

      
      <UiSort 
        @choose="changeSort"
        :val="'harvests'"    
        :options="[
          {id:'rate', name: $t('universal_sort_reviews2')},    
          {id:'az', name: $t('universal_sort_az2')},        
          {id:'gplant', name: $t('universal_sort_gplant')},      
          {id:'harvests', name: $t('universal_sort_harvests2')},      
          {id:'diaries', name: $t('universal_sort_diaries2')},      
          {id:'growers', name: $t('universal_sort_growers2')},      
        ]"
        />
      
    </div>


    <SkGrowerRows
      v-if="is_loading && start == 0"
      />

    <div 
      v-else-if="brand_lamp_data?.items.length"
      class="catalog_content results two_column">
      <template 
        v-for="item in brand_lamp_data.items"        
        >     
        <GeneralCatalogRow
          :rating="item.item_stat.rate"
          :logo="item.avatar_small"
          :link="'/grow-lights/' + item.section"
          :nm="item.name"
          :country="item.country"
          :item_stat="item.item_stat"
          :products="item.item_stat.cnt_items"
          :diaries="item.cnt_reports"
          :growers="item.cnt_growers"
          :harvests="item.cnt_harvests"
          :rate="item.avg_rate"
          :chat-user="item.offical_user_id"
          :types="item.spec_props"
        />
      </template>
     
      <client-only>
        <InfinityScroll 
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>
      </client-only>
              
    </div>

    <UiEmpty
      v-else
      />
  

  </div>
</template>

<script setup>
 
 

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'
import InfinityScroll from '~/components/InfinityScroll.vue';


const start = ref(0)
const limit = ref(20)
const is_ended = ref(false)
const {$api, $tagsUtil, $ga} = useNuxtApp()
const route = useRoute();
const el_infinity_scroll = ref(null)
const sort = ref('diaries')
const keyword = ref(route.query['keyword']?.length ? route.query['keyword'] : '')
const tagscount = ref([]);
const tagsselected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const tagsdefault = ref(null);
const tags = ref($tagsUtil.tagsBrands)
const tagspermanent = ref([]);

tagspermanent.value.push('cg:lamp');

const changeSort = function(val) {  
  start.value = 0;
  sort.value = val;
}  

const changeKeyword = function(val) {  
  start.value = 0;
  keyword.value = val;
}  



const loadData = async function() {        
  const response = await $api.getBrands({
    keyword: keyword.value,
    sortable: sort.value,
    start: start.value,
    limit: limit.value,
    tagsall: $tagsUtil.getTagsKeys(tags.value), 
    tags: $tagsUtil.getTagsSimple(tags.value, tagsselected.value),
    tagsper: $tagsUtil.getTagsSimple(props.intags, tagspermanent.value)
  })  
  return response;  
}



const { pending: is_loading,  data: brand_lamp_data } = await useLazyAsyncData('brand_lamp_data', async () => await loadData())



watchArray([start, sort, keyword], async ([new_start, new_sort, keyword], added, removed) => {
  console.log('watcher');
  is_loading.value = true;
  
  var dt = await loadData();

  if(new_start == 0){
    brand_lamp_data.value.items = [];
    is_ended.value = false;
  }  

  if(dt.length == 0)
    is_ended.value = true;
  
  brand_lamp_data.value.items = [...brand_lamp_data.value.items, ...dt.items]  

  is_loading.value = false;
}, {deep: true})



useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


useHead(useNuxtApp().$head.getBrandLampList());

watchArray([route], async ([new_route], added, removed) => {
  
  useHead(useNuxtApp().$head.getBrandLampList());

}, {deep: true})
 
</script>

<style scoped>


h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
}
 

</style>
